/* eslint-disable */
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";

// Plugins custom css
import "assets/theme/base/plugins.css";
import Navbar from "components/components/Navbar/Navbar";
import Home from "components/components/Home/Home";
import Information from "components/components/Information/Information";
import Questions from "components/components/Questions/Questions";
import Footer from "components/components/Footer/Footer";
import Pricing from "components/components/Pricing/Pricing";
import { Suspense, lazy, useEffect, useState } from "react";
const lazyPricing = lazy(() => import('components/components/Pricing/Pricing'));
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Contactus from "components/components/Contact/Contactus";
const lazyContactus = lazy(() => import('components/components/Contact/Contactus'));
import Conversational from "components/components/Solutions/Conversational";
const lazyConversational = lazy(() => import('components/components/Solutions/Conversational'));
const lazyTextToVideo = lazy(() => import('components/components/Solutions/TextToVideo'));
const lazyPersonalization = lazy(() => import('components/components/Solutions/Personalization'));
const lazyVideoTutorial = lazy(() => import('components/components/Solutions/VideoTutorial'));
import TextToVideo from "components/components/Solutions/TextToVideo";
import Personalization from "components/components/Solutions/Personalization";
const Blogs = lazy(() => import("components/components/Blogs/Blogs"));
const ShowProducts = lazy(() => import("components/components/ShowProducts/ShowProducts"));
const Form = lazy(() => import("components/components/Form/Form"));
import { setWidget, useVisionUIController } from 'context';
import ChatButton from "components/components/Chatbot/ChatButton";
import { NavLink, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Features = lazy(() => import("components/components/Features/Features"));
import UseBlocks from 'components/components/UseBlocks/Features/Features';
import Modal from '@mui/material/Modal';
import { Box, Button, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import pImage from './Pop-up Image 2.webp';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import FormField from "layouts/applications/wizard/components/FormField";
import VuiButton from "components/VuiButton";
import ExitModal from "components/components/ExitModal/ExitModal";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
const BlogsDetails = lazy(() => import("components/components/Blogs/BlogDetails"));
import CMSBlogsDetails from "components/components/CMSBlogs/BlogDetails";
import SalesMarketing from "components/components/UseCases/Sales&Marketing";
import CustomerService from "components/components/UseCases/CustomerService";
import Enterprises from "components/components/UseCases/Enterprises";
const NotFound = lazy(() => import("components/components/NotFound"));
import Education from "components/components/UseCases/Education";
const FeaturesAiAvatar = lazy(() => import("components/components/UseCases/Features/features-ai-avatars"));
const FeaturesVoiceStyle = lazy(() => import("components/components/UseCases/Features/features-voice-style"));
const FeaturesScriptEditor = lazy(() => import("components/components/UseCases/Features/features-script-editor"));
const FeaturesLanguages = lazy(() => import("components/components/UseCases/Features/features-languages"));
import Learning from "components/components/UseCases/Learning";
import CreateMessage from "components/components/CreateMessage";
import SocialMedia from "components/components/UseCases/SocialMedia";
const lazyPersonalizationMessage = lazy(() => import('components/components/PersonalisedMessage'));
import PersonalisedMessage from "components/components/PersonalisedMessage";
import VuiTypography from "components/VuiTypography";
import CelebrationIcon from '@mui/icons-material/Celebration';
import VideoTutorial from "components/components/Solutions/VideoTutorial";
import CMSBlogs from "components/components/CMSBlogs";
import CarouselSection from "components/components/CarouselSection/CarouselSection";
const UseSlides = lazy(() => import("components/components/languageShowcase/UseSlides"));
const FeaturesVideoLibrary = lazy(() => import("components/components/UseCases/Features/features-video-library"));
const BlogMaker =lazy(() => import("components/components/BlogMaker"));
const featuresAudio = lazy(() => import("components/components/UseCases/Features/features-audio"));
const featuresVideoChatbots = lazy(() => import("components/components/UseCases/Features/features-video-chatbots"));
const BlogPost = lazy(() => import("components/components/Blogs/BlogPost"));
import PrivacyPolicy from "components/components/Policies/privacy-policy";
import TermsOfService from "components/components/Policies/terms-of-service";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { widget } = controller;
  const [heading, setHeading] = useState(true);
  // const location = useLocation();
  // const isPersonalisedMessagePage = useRouteMatch('/personalisedmessage');

  // useEffect(() => {
  //   console.log('Location Pathname:', location.pathname);
  //   console.log('Is Personalised Message Page:', isPersonalisedMessagePage);
  // }, [location.pathname, isPersonalisedMessagePage]);

  useEffect(() => {
    // setTimeout(() => {
    //   handleOpen();
    // }, 1000);
    setWidget(dispatch, !widget);
    heading && window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    return () => {
      document.head.removeChild(gtmScript);
    };
  }, []);

  const scrollToSection = (sectionId) => {

    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const sectionRect = section.getBoundingClientRect().top;
      const offsetPosition = sectionRect - bodyRect + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ExitModal />
        {heading && window.innerWidth > 768 && <div style={{posigtion: 'relative'}}>
          <a href='https://app.quickvideo.ai/signup'><VuiTypography variant='h6' color='white' textAlign='center' sx={{background: 'rgb(50, 50, 100)', padding: '5px'}}><span style={{textDecoration: 'underline', color: 'skyblue', textUnderlineOffset: '5px'}}>Sign up </span> now and get 10 free credits today! &nbsp; Start creating studio quality videos at scale.</VuiTypography></a>
          <CloseIcon color="white" sx={{position: 'absolute', right: 10, top: 7, cursor: 'pointer'}} onClick={()=>setHeading(false)} />
        </div>}
        <Navbar scrollToSection={scrollToSection} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" render={() => 
              <>
                <Home />
                <CarouselSection />
                <ShowProducts />
                {/* <UseBlocks />/ */}
                <Features />
                <Information />
                <UseSlides />
                {/* <Questions /> */}
                <Form />
              </>}
            />
            <Route path="/quickvideo-pricing" component= {lazyPricing} />
            <Route path="/contact-us" component={lazyContactus} />
            <Route path="/conversational-ai" component={lazyConversational} />
            <Route path="/text-to-video-ai-generator" component={lazyTextToVideo} />
            <Route path="/personalized-ai-video" component={lazyPersonalization} />
            <Route path="/saas-tutorial" component={lazyVideoTutorial} />
            {/* <Route path="/createvideomessage" component={CreateMessage} /> */}
            <Route path="/personalisedmessage" component={lazyPersonalizationMessage} />
            {/* <Route path="/sales&marketing" component={SalesMarketing} />
            <Route path="/customer_service" component={CustomerService} />
            <Route path="/enterprise_employee_engagement" component={Enterprises} />
            <Route path="/education" component={Education} />
            <Route path="/learning&development" component={Learning} />
            <Route path="/socialmedia" component={SocialMedia} /> */}
            <Route path="/blogs/:slug" component={BlogsDetails} />
            <Route path="/blogs" component={Blogs} />
            <Route path="/newblogs/:slug" component={BlogPost} />
            {/* <Route path="/newblogs" component={CMSBlogs} /> */}
            <Route path="/addanewblogCMS" component={BlogMaker} />
            <Route path="/features-ai-avatars" component={FeaturesAiAvatar} />
            <Route path="/features-audio" component={featuresAudio} />
            <Route path="/features-voice-style" component={FeaturesVoiceStyle} />
            <Route path="/features-script-editor" component={FeaturesScriptEditor} />
            <Route path="/features-video-chatbots" component={featuresVideoChatbots} />
            <Route path="/features-languages" component={FeaturesLanguages} />
            <Route path="/features-video-library" component={FeaturesVideoLibrary} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route component={NotFound} />
            {/* <Redirect to="/" /> */}
          </Switch>
        </Suspense>
        <Footer scrollToSection={scrollToSection} />
      </Router> 
      {/* {widget && <ChatButton />} */}
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    </ThemeProvider>
  );
}