import React, { useEffect } from 'react';
import PricingPage from 'layouts/pages/pricing-page';
import Questions from '../Questions/Questions';

const Pricing = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'QuickVideo pricing';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = "Unleash the power of an AI video generator with QuickVideo’s flexible pricing plans starting from just $20 per month. Create captivating videos with ease. Read more about our pricing options.";
    document.head.appendChild(metaDescription);

    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(gtmScript);
    };
  }, []);

  return (
    <div id='pricing'>
      <PricingPage />
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
      {/* <Questions /> */}
    </div>
  )
}

export default Pricing