import { CircularProgress } from '@mui/material';
import axios from 'axios';
import VuiBox from 'components/VuiBox';
import VuiButton from 'components/VuiButton';
import VuiTypography from 'components/VuiTypography';
import FormField from 'layouts/applications/wizard/components/FormField';
import React, { useEffect, useState } from 'react';

const Form = () => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nameError, setNameError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [number, setNumber] = useState('');
  const [res, setRes] = useState('');
  const [resToggle, setResToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  useEffect(() => {
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    return () => {
      document.head.removeChild(gtmScript);
    };
  }, []);

  const postDetails = async () => {
    setLoader(true);
    await axios
      .post('https://quick.quickvideo.ai/web_leads', {
        full_name: name,
        email_id: email,
        business_name: businessName,
        mobile: number,
      })
      .then((res) => {
        setRes(res.data.message);
        setResToggle(true);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setResToggle(false);
      setRes('');
      setName('');
      setEmail('');
      setBusinessName('');
      setNumber('');
    }, 3000);
  }, [resToggle]);

  const handleName = (e) => {
    setName(e.target.value);

    // Check if the input contains any numbers
    if (/[0-9]/.test(e.target.value)) {
      setNameError('Name should not contain numbers.');
    } else {
      setNameError('');
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(e.target.value)) {
      setEmailError('Invalid! please enter a valid email.');
    } else {
      setEmailError('');
    }
  };

  const handleBusinessName = (e) => {
    const input = e.target.value;

    // Check if the input contains any numbers
    if (/[0-9]/.test(input)) {
      setBusinessNameError('Business name should not contain numbers.');
    } else {
      setBusinessNameError('');
    }
    setBusinessName(input);
  };

  const handleNumber = (value) => {
    setNumber(value);
    const phoneRegex = /^[0-9]{10,}$/;
    if (!phoneRegex.test(value.replace(/\D/g, ''))) {
      setPhoneError('Enter valid phone number');
    } else {
      setPhoneError('');
    }
  };

  const phoneInputStyle = {
    width: '100%',
    height: '38px',
    fontSize: '14px',
    borderRadius: '15px',
    backgroundColor: '#0f1535',
    border: '0.0625rem solid #56577a',
    color: 'whitesmoke',
    ...isPhoneFocused && { boxShadow: '0rem 0rem 0rem 0.125rem rgba(98, 143, 194, 1)' },
  };

  return (
    <>
      {loader ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <CircularProgress color="white" size={30} />
        </div>
      ) : (
        <VuiBox id="contact" sx={{ padding: window.innerWidth <= 800 ? '50px 20px' : '50px 200px' }}>
          {resToggle === true ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '50vh',
                color: 'whitesmoke',
                fontSize: 30,
              }}
            >
              {res}
            </div>
          ) : (
            <>
              <VuiBox width="100%" textAlign="center" mb="20px">
                <VuiBox mb={1}>
                  <VuiTypography variant={window.innerWidth <= 800 ? 'h5' : 'h2'} fontWeight="regular" color="white">
                    Get in touch!
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: window.innerWidth <= 800 ? '0px' : '20px',
                    flexDirection: window.innerWidth <= 800 ? 'column' : '',
                  }}
                >
                  <div style={{ width: window.innerWidth <= 800 ? '300px' : '400px', marginRight: '20px' }}>
                    <FormField type="text" label="full name" placeholder="Eg. Michael" onChange={handleName} value={name} />
                    {nameError && name !== '' && <h6 style={{ color: 'red' }}>{nameError}</h6>}
                  </div>
                  <div style={{ width: window.innerWidth <= 800 ? '300px' : '400px', marginRight: '20px' }}>
                    <FormField
                      type="text"
                      label="Business name"
                      placeholder="Eg. QuickVideo"
                      onChange={handleBusinessName}
                      value={businessName}
                    />
                    {businessNameError && businessName !== '' && <h6 style={{ color: 'red' }}>{businessNameError}</h6>}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: window.innerWidth <= 800 ? 'column' : '',
                  }}
                >
                  <div style={{ width: window.innerWidth <= 800 ? '300px' : '400px', marginRight: '20px' }}>
                    <FormField
                      type="text"
                      label="email address"
                      placeholder="Eg. micheal@xyz.com"
                      onChange={handleEmail}
                      value={email}
                    />
                    {emailError && email !== '' && <h6 style={{ color: 'red' }}>{emailError}</h6>}
                  </div>
                  <div style={{ width: window.innerWidth <= 800 ? '300px' : '400px', marginRight: '20px' }}>
                    <FormField
                      type="phone"
                      label="phone number"
                      placeholder="Eg. 9876543210"
                      onChange={handleNumber}
                      value={number}
                      onFocus={() => setIsPhoneFocused(true)}
                      onBlur={() => setIsPhoneFocused(false)}
                      phoneInputStyle={phoneInputStyle}
                    />
                    {phoneError && number !== '' && <h6 style={{ color: 'red' }}>{phoneError}</h6>}
                  </div>
                </div>
                <VuiButton
                  sx={{ marginTop: '30px' }}
                  onClick={postDetails}
                  disabled={
                    name === '' ||
                    email === '' ||
                    number === '' ||
                    businessName === '' ||
                    phoneError !== '' ||
                    emailError !== '' ||
                    businessNameError !== '' ||
                    nameError !== ''
                  }
                >
                  Submit
                </VuiButton>
              </div>
            </>
          )}
        </VuiBox>
      )}
    </>
  );
};

export default Form;
